.guestsContainer {
  padding: 0 16px;
}

.guestsContainer .allConfirmed {
  font-size: 20px;
  margin: 0;
}

.guestContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 4px;
}

.guestContent p {
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.guestContent span {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
}