@import url('https://fonts.googleapis.com/css2?family=Meow+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.container_svg {
    width: 100%;
}

.container_header {
    background-image: url('../../assets/images/Vector8.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 102%;
    margin-left: -1px;
    margin-top: -1px;
    height: 122px;
}

.container_header-title {
    margin: 0 0 20px 22px;
}

.container_header-title a {
    text-decoration: none;
    color: #FFF;
}

.container_header-title h1 {
    font-size: 48px;
    margin: 0;
    line-height: 0.9;
}

.container_header-title h2 {
    font-size: 32px;
    margin: 0;
    line-height: 0.9;
}

.container_header-title small {
    font-size: 17px;
    font-weight: bold;
}

.container_header-subtitle h2 {
    font-family: "Parisienne", cursive;
    font-size: 30px;
    margin: 30px;
    text-align: center;
    line-height: 24px;
    color: #FFF;
}

@media (min-width: 768px) {
    .container_header {
        background-image: url('../../assets/images/Vector9.svg');
    }
}

@media(max-width: 360px) {
    .container_header-subtitle h2 {
        margin-top: 8px;
    }
    
    .container_header-title h1 {
        font-size: 40px;
    }

    .container_header-title small {
        font-size: 15px;
    }
}
