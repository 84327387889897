.image_md {
    height: 259px;
    width: 175px;
}

.image_sm {
    height: 220px;
    width: 176px;
}

.image_lg {
    height: 173px;
    width: 90%;
    object-fit: cover;
}

.container_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.container_image span {
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #353535;
}

.container_image img {
    border-radius: 10px;
}

.content_images {
   width: 100%;
}

@media (min-width: 1024px) {
    .content_images {
        display: flex;
        gap: 20px;
    }

    .container_image {
        width: 250px;
        margin: 0 auto;
    }

    .image_md {
        width: 250px;
        height: auto;
    }
    
    .image_sm {
        width: 250px;
        height: auto;
    }
    
    .image_lg {
        width: 650px;
        height: 250px;
    }
}

@media(max-width: 375px) {
    .container_image img:not(.image_category) {
        max-width: 160px;
    }
}
