.contentFormConfirmation {
  padding: 0 16px;
}

.formConfirmation {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.formConfirmation input:not([type="radio"]) {
  height: 32px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #353535;
  padding: 8px;
}

.inputsConfirmation {
  display: flex;
  align-items: flex-start;
}

.inputsConfirmation label {
  display: flex;
  gap: 8px;
}

.inputsConfirmation select {
  font-size: 40px;
}

.guestsQuantity {
  display: flex;
  align-items: center;
}

.guestsQuantity span {
  font-size: .75rem;
}

.contentFormConfirmation button {
  margin-top: 32px;
}

.successMsg {
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
}

.backToForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backToForm p {
  text-decoration: underline;
  color: #353535;
  margin: 16px 0 0 0;
  font-size: 14px;
}

.modal_confirmation h3 {
  font-size: 20px;
  font-weight: 500;
}

@media(max-width: 1024px) {
  .guestsQuantity, 
  .inputsConfirmation {
    justify-content: space-between;
  }

  .formConfirmation input:not([type="radio"]) {
    width: 100%;
  }
}